<template>
    <div class="layout-body" v-if="editObj.en">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Launch -->
        <div class="offers-body">

            <div class="edit-top">
                <span class="name">{{ launchId === ''?'New':'' }} Offer</span>
            </div>

            <div class="edit-check edit-border-bottom">
                <div class="box">
                    <ul>
                        <li :key="key" v-for="(lang,key) in language">
                            <i class="label-icon" :class="lang.checkBox?'select':'selected'"></i>
                            <span class="label-name" :class="lang.checkBox?'title':''">{{ lang.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="edit-check edit-data">
                <ul>
                    <li>
                        <div class="title">
                            <span class="name">Start</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.startTime" placement="bottom-start" type="date" @on-change="onStartMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.startTime) }}</div>
                    </li>
                    <li v-if="endStatus">
                        <div class="title">
                            <span class="name">End</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.endTime" placement="bottom-start" type="date" @on-change="onEndMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.endTime) }}</div>
                    </li>
                    <li>
                        <div class="label" @click="onEndStatus">
                            <i class="label-icon" :class="endStatus?'select':'selected'"></i>
                            <span class="label-name">TBD</span>
                        </div>
                    </li>
                    <li>
                        <div class="title">
                            <span class="name store">Store / Brand</span>
                        </div>
                        <div class="input-box">
                            <input type="text" placeholder="Text" class="input" v-model="editObj.en.source"/>
                        </div>
                    </li>
                </ul>
            </div>

            <!--s: cover-->
            <div class="launch-box">
                <h1 class="headline">Cover & Content</h1>
                <div class="launch-terminal">
                    <a href="javascript:" :class="editObj.en.osType === item.id?'active':''" :key="key" v-for="(item,key) in terminal" @click="onTerminal(item.id)">{{ item.name }}</a>
                </div>

                <div class="launch-plug">
                    <div class="launch-upload">
                        <span class="title">Logo Image - 220 x 98</span>
                        <div class="upload">
                            <span class="value">{{ editObj.en.logoImage }}</span>
                            <Button>Upload<input type="file" class="input" @change="onFileDataChange"/></Button>
                        </div>
                    </div>
                </div>
                <div class="launch-text image-text">
                    <div class="box">
                        <div class="title">
                            <span class="name store">Text</span>
                        </div>
                        <div class="input-box">
                            <input type="text" v-model="editObj.en.saleText" placeholder="x" class="input" />
                        </div>
                    </div>
                    <div class="box">
                        <div class="title">
                            <span class="name store">文字</span>
                        </div>
                        <div class="input-box">
                            <input type="text" v-model="editObj.cn.saleText" placeholder="几折" class="input" />
                        </div>
                    </div>
                </div>
                <div class="edit-check">
                    <div class="box">
                        <ul>
                            <li @click="onExclusive" onselectstart="return false">
                                <i class="label-icon" :class="editObj.en.exclusive === 0?'selected':'select'"></i>
                                <span class="label-name">Exclusive</span>
                            </li>
                            <li @click="onPriority" onselectstart="return false">
                                <i class="label-icon" :class="editObj.en.priority === 0?'selected':'select'"></i>
                                <span class="label-name">Priority</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Text</span>
                            <span style="float:right;">Montserra - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.saleContent" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box">
                        <div class="title">
                            <span style="float:left;">说明</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserra - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.saleContent" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
                <div class="launch-text image-text">
                    <div class="box" v-if="neededStatus">
                        <div class="title">
                            <span class="name store">Code</span>
                        </div>
                        <div class="input-box">
                            <input type="text" v-model="editObj.en.code" placeholder="xxxxxx" class="input" />
                        </div>
                    </div>
                    <div class="box">
                        <div class="label" @click="onEndNeeded">
                            <i class="label-icon" :class="neededStatus?'select':'selected'"></i>
                            <span class="label-name">No code needed</span>
                        </div>
                    </div>
                </div>
              </div>
            <!--e: cover-->

            <!--s: Content-->
            <div class="launch-box launch-margin-top">
                <h1 class="headline">Content</h1>
                <div class="launch-terminal">
                    <a href="javascript:" class="select">Link</a>
                </div>
                <!--s: Link-->
                <div class="launch-link">
                    <div class="box">
                        <span class="title">Link</span>
                        <div class="input-box">
                            <input v-model="editObj.en.link" type="text" class="input" placeholder="URL"/>
                        </div>
                    </div>
                </div>
                <!--e: Link-->
            </div>
            <!--e: Content-->


            <!--s: Btn-->
            <div class="launch-btn-edit">
                <div class="box">
                    <Button style="margin-left: 30px" :loading="loadingStatus" @click="onEditSubmit">Save</Button>
                </div>
            </div>
            <!--s: Btn-->

        </div>
        <!--e: Launch -->

    </div>
</template>
<script>
import offersEdit from './edit'
export default offersEdit
</script>
<style lang="less">
@import "edit";
</style>
